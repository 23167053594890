import axios from './config'
import { baseUrl } from './config'
import store from '../store'
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({
  position: "top"
});

export function uploadFile (data, progressCb) {
  return axios({
    url: '/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent) => {
      progressCb(progressEvent)
    },
    data
  })
}

export function sendMsg (data) {
  return axios({
    url: '/feedback/sendmsg',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

export function login (data) {
  return axios({
    url: '/token',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

// stream 接口需要用 fetch 请求
export function gptQuery (options) {
  return fetch(`${baseUrl}/query`, options).then(response => {
    if (response.ok) {
      // 请求成功，处理响应数据
      return response
    } else if (response.status === 401) {
      // 未授权的请求，执行相应操作
      store.commit('clearToken')
      toaster.warning('请重新登录！')
    } else {
      // 其他错误状态码，抛出错误
      throw new Error('Request failed with status code: ' + response.status);
    }
  })
}

// 获取文书优化方案
export function gptOptimize (data, cancelToken) {
  return axios({
    url: '/optimize',
    method: 'post',
    data,
    cancelToken
  })
}

// 文档管理 获取文件
export function getFiles (params) {
  return axios.get(`/list_files`, { params })
}

// 文档管理 删除文件
export function delFiles (data) {
  return axios({
    url: '/delete_files',
    method: 'post',
    data
  })
}

// 重置配置
export function resetConfiguration () {
  return axios.get('/reset_setting')
}

// 获取配置
export function getConfiguration () {
  return axios.get('/setting')
}

// 设置配置
export function setConfiguration (data) {
  return axios.post('/setting', data)
}

// 处理文档
export function processData (data) {
  return axios.post('/process_data_async', data)
}

// 获取知识库
export function getKb (params) {
  return axios.get('/list_kbs', {params})
}

// 删除知识库
export function delKb (data) {
  return axios.post('/delete_kbs', data)
}

// 删除知识库
export function loadKb (data) {
  return axios.post('/load_kbs', data)
}

export function feedback (options) {
  return fetch(`${baseUrl}/feedback`, options).then(response => {
    if (response.ok) {
      // 请求成功，处理响应数据
      return response
    } else if (response.status === 401) {
      // 未授权的请求，执行相应操作
      store.commit('clearToken')
      toaster.warning('请重新登录！')
    } else {
      // 其他错误状态码，抛出错误
      throw new Error('Request failed with status code: ' + response.status);
    }
  })
}

export function addKb (data) {
  return axios.post('/add_kbs/', data)
}

export function downloadFile (file_id) {
    try {
      const response = axios.post('/download/' + file_id, { /* 可选的请求数据 */ }, { responseType: 'blob' });
      const url = URL.createObjectURL(new Blob([response.data]));
      console.log(url)
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.location.href = url;
      } else {
        // 弹出窗口被浏览器阻止了
        alert('下载已被浏览器阻止，请检查浏览器设置');
      }
    } catch (error) {
      console.error('下载文件失败:', error);
    }
}