<template>
  <div class="dialog" v-show="props.modelValue">
    <div class="dialog-content">
      <slot></slot>
      <div class="close-btn" @click="closeDialog">×</div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits();
const props = defineProps({
  modelValue: Boolean,
});
const closeDialog = () => {
  emit('update:modelValue', false)
  emit('onClose', false)
}
</script>

<style lang="less" scoped>
.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 999;

  .dialog-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: #fff;
    padding: 10px;
    border-radius: 12px;

    .close-btn {
      position: absolute;
      top: -26px;
      right: -24px;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-size: 32px;
      opacity: .9;
      background: #b1b1b1;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
</style>