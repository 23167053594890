import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import './assets/iconfont/iconfont.css'
import './assets/iconfont/iconfont.js'

import { ElButton, ElTable, ElTableColumn, ElPagination, ElLoading, ElCard, ElCheckbox, ElInputNumber, ElInput, ElSelect, ElOption, ElDropdown, ElDropdownMenu, ElDropdownItem, ElTag, ElDialog, ElForm, ElFormItem, ElText } from 'element-plus';
import 'element-plus/theme-chalk/index.css';

const app = createApp(App)
app.use(store).use(router).use(ElLoading).use(ElLoading.directive).mount('#app')


app.component(ElButton.name, ElButton)
app.component(ElTable.name, ElTable)
app.component(ElTableColumn.name, ElTableColumn)
app.component(ElPagination.name, ElPagination)
app.component(ElCard.name, ElCard)
app.component(ElCheckbox.name, ElCheckbox)
app.component(ElInputNumber.name, ElInputNumber)
app.component(ElInput.name, ElInput)
app.component(ElSelect.name, ElSelect)
app.component(ElOption.name, ElOption)
app.component(ElDropdown.name, ElDropdown)
app.component(ElDropdownMenu.name, ElDropdownMenu)
app.component(ElDropdownItem.name, ElDropdownItem)
app.component(ElTag.name, ElTag)
app.component(ElDialog.name, ElDialog)
app.component(ElForm.name, ElForm)
app.component(ElFormItem.name, ElFormItem)
app.component(ElText.name, ElText)
